import 'babel-polyfill';
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
import { sessionService } from './_packages/redux-react-session';
import { store } from './_helpers';
import './index.css';
import { App } from './app/App';
import jwtInterceptor from './_helpers/jwt-interceptor';
import 'bootstrap/dist/js/bootstrap.bundle';
import * as $ from 'jquery';
import { createRoot } from 'react-dom/client';
  
(function(){

     
    if (!sessionStorage.length) {
      // Ask other tabs for session storage
      localStorage.setItem('getSessionStorage', Date.now());
    };

    window.addEventListener('storage', function(event) {

      //console.log('storage event', event);

      if (event.key === 'getSessionStorage') {
        // Some tab asked for the sessionStorage -> send it

        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
        localStorage.removeItem('sessionStorage');

      } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
        // sessionStorage is empty -> fill it

        var data = JSON.parse(event.newValue),
              value;

        for (let key in data) {
          sessionStorage.setItem(key, data[key]);
        }

        //showSessionStorage();
      }
    });

    $(document).ready(function(){
        setTimeout(()=>{
          if(sessionStorage.getItem('loginStatus') != 'true') {
              let cookies = document.cookie.split(";");
              for (let i = 0; i < cookies.length; i++) {
                  let cookie = cookies[i];
                  let eqPos = cookie.indexOf("=");
                  let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
              }
            }
        },100)
      })

})()

//Here is the guy where I set up the interceptors!
jwtInterceptor.setupInterceptors();

// Init the session service
const options = {refreshOnCheckAuth: false, driver: 'COOKIES', expires: ''};
sessionService.initSessionService(store, options);

const container = document.getElementById('root');
const root = createRoot(container)
root.render(
  //  <HttpsRedirect> For production env only
      <Provider store={store}>
          <App />
      </Provider>
  // </HttpsRedirect>
);


